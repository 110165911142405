<template>
    <RouterTabs :paths="paths"></RouterTabs>
</template>

<script>
  import RouterTabs from "@/components/Common/RouterTabs";

  export default {
    name: "EfficiencyView",
    components: {
      RouterTabs
    },
    computed: {
      paths () {
        return [
          {
            name: "admin:efficiency:efficiency",
            label: this.$t("admin.efficiency.tabs.efficiency"),
            permissions: [
              "MANAGER_EFFICIENCY.VIEW"
            ]
          },
          {
            name: "admin:efficiency:plans",
            label: this.$t("admin.efficiency.tabs.plans"),
            permissions: [
              "MANAGER_EFFICIENCY.VIEW"
            ]
          }
        ];
      }
    }
  };
</script>
