<template>
    <div class="wrapper">
        <EfficiencyFilters></EfficiencyFilters>
        <EfficiencyTable></EfficiencyTable>
    </div>
</template>

<script>
  import { mapMutations } from "vuex";
  import { SET_EMPTY } from "@core/store/mutation-constants";
  import EfficiencyFilters from "@/components/Admin/Efficiency/EfficiencyFilters";
  import EfficiencyTable from "@/components/Admin/Efficiency/EfficiencyTable";

  export default {
    name: "Efficiency",

    components: { EfficiencyFilters, EfficiencyTable },

    methods: {
      ...mapMutations("admin/efficiency", [SET_EMPTY])
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style scoped>

</style>
