<template>
    <div class="columns is-multiline">
        <div class="column is-2">
            <b-field :label="$t('webmaster.statistics.statistics.filters.labels.groups')">
                <b-select
                    v-model="group"
                    expanded>
                    <option
                        :key="index"
                        v-for="(option, index) of period"
                        :value="option">
                        {{ $t(`webmaster.statistics.statistics.filters.values.groupsList.${ option }`) }}
                    </option>
                </b-select>
            </b-field>
        </div>
        <div class="column is-narrow">
            <CustomDatepicker v-model="datepicker"></CustomDatepicker>
        </div>
    </div>
</template>

<script>
  import CustomDatepicker from "@/components/Common/CustomDatepicker";
  import { momentInst } from "@core/filters";
  import { momentToDate } from "@core/helpers/momentToDate";
  import { Filters } from "@core/mixins";
  import { GET_EFFICIENCY } from "@core/store/action-constants";
  import { SET_GROUP, UPDATE_EFFICIENCY_FILTERS } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import _debounce from "lodash/debounce";
  import momentjs from "moment";
  import { mapActions, mapMutations, mapState } from "vuex";
  
  export default {
    name: "EfficiencyFilters",
    mixins: [Filters],
    components: { CustomDatepicker },

    created () {
      const {
        dateStart,
        dateEnd
      } = this.$route.query;

      this.datepicker = {
        dateStart: momentToDate(
          dateStart
            ? momentInst(dateStart).startOf("day")
            : momentInst().startOf("day").subtract(1, "month")
        ),
        dateEnd: momentToDate(momentInst(dateEnd || undefined).endOf("day"))
      };
    },

    computed: {
      ...mapState("admin/efficiency",{
        efficiencyGroup: state => state.group,
        efficiencyFilters: state => state.filters
      }),
      
      ...mapFields("admin/efficiency", {
        fields: ["datepicker"],
        base: "filters",
        action: UPDATE_EFFICIENCY_FILTERS
      }),
      
      group: {
        get () {
          return this.efficiencyGroup.toLowerCase();
        },
        
        set (value) {
          this.SET_GROUP(value.toUpperCase());
        }
      },
      
      period () {
        return ["day", "week", "month"];
      },
  
      debouncedUpdate () {
        return _debounce(this.debouncedRequest, 400, { leading: true, trailing: true });
      }
    },
    methods: {
      ...mapMutations("admin/efficiency", [
        SET_GROUP
      ]),
      
      ...mapActions("admin/efficiency", [
        GET_EFFICIENCY
      ]),
      
      debouncedRequest () {
        this.GET_EFFICIENCY();

        const { datepicker: { dateStart, dateEnd }, group } = this;
        const query = {
          dateStart: momentjs(dateStart).format("YYYY-MM-DD"),
          dateEnd: momentjs(dateEnd).format("YYYY-MM-DD"),
          group
        };

        this.$router.push({
          query
        }).catch(_ => {});
      }
    },
    watch: {
      efficiencyFilters: {
        deep: true,
        handler () {
          this.debouncedUpdate();
        }
      },
      
      efficiencyGroup: {
        deep: true,
        handler () {
          this.debouncedUpdate();
        }
      }
    }
  };
</script>

<style scoped>

</style>
