<template>
    <TablePlaceholder :data="efficiency">
        <template #default="{ items }">
            <b-table
                :data="items"
                :loading="isLoadingEfficiency"
                class="common-table">
                <template
                    slot="header"
                    slot-scope="{ column }">
                    <div class="th-wrap">
                        {{ column.label }}
                    </div>
                </template>
        
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.efficiency.total.table.labels.id`)">
                    {{ row.admin.intId }}
                </b-table-column>
        
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.efficiency.total.table.labels.login`)">
                    {{ formatEmptyString(row.admin.login) }}
            
                    <LTooltip
                        v-if="row.admin.activity.isBlocked"
                        :label="$t(`admin.users.administrators.filters.values.blocked`)"
                        position="is-bottom"
                        type="is-dark">
                        <b-icon
                            class="has-text-light"
                            icon="ban"
                            size="is-small">
                        </b-icon>
                    </LTooltip>
            
                    <LTooltip
                        v-if="!row.admin.activity.isConfirmed"
                        :label="$t(`admin.users.administrators.filters.values.emailUnConfirmedIcon`)"
                        position="is-bottom"
                        type="is-dark">
                        <b-icon
                            class="has-text-light line-through"
                            icon="envelope"
                            size="is-small">
                        </b-icon>
                    </LTooltip>
            
                    <template v-if="row.admin.name">
                        <br>
                        <small>{{ row.admin.name }}</small>
                    </template>
                </b-table-column>
        
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.efficiency.total.table.labels.webmastersAmount`)">
                    {{ row.admin.activeWebmastersAmount }} /
                    <LTooltip :label="$t(`admin.efficiency.total.table.tooltips.partnerNetworks`)">
                        <span>{{ row.admin.partnerNetworksAmount }}</span>
                    </LTooltip>
                </b-table-column>
        
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.efficiency.total.table.labels.leadsAmount`)">
                    {{ `${ row.countLeadsCreated } (${ row.averageLeadsCreated })` }}
                </b-table-column>
        
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.efficiency.total.table.labels.approvesAmount`)">
                    {{ `${ row.countLeadsAccepted } (${ row.averageLeadsAccepted })` }}
                </b-table-column>
        
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.efficiency.total.table.labels.plan`)">
                    {{ `${ row.countLeadsAcceptedPlan } (${ row.averageLeadsAcceptedPlan })` }}
                </b-table-column>


                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.efficiency.total.table.labels.percent`)"
                    style="">
                    {{ row.percentLeadsAcceptedPlan }} %
                    <div 
                        class="progressbar" 
                        :style="{ width: row.percentLeadsAcceptedPlan + '%' } ">
                    </div>
                </b-table-column>
                
        
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.efficiency.total.table.labels.commission`)">
                    {{ formatCurrency(row.sumCommission) }}
                </b-table-column>
        
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.efficiency.total.table.labels.approveCommission`)">
                    {{ formatCurrency(row.averageCommission) }}
                </b-table-column>
        
                <template slot="footer">
                </template>
            </b-table>
        </template>
    </TablePlaceholder>
</template>

<script>
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import { Table } from "@core/mixins";
  import { GET_EFFICIENCY } from "@core/store/action-constants";
  import { mapActions, mapState } from "vuex";
  
  export default {
    name: "EfficiencyTable",
    mixins: [Table],
    components: { TablePlaceholder },
  
    async created () {
      await this.GET_EFFICIENCY();
    },

    computed: {
      ...mapState("admin/efficiency", {
        efficiency: state => state.data
      }),
    
      // FIXME: Как только из миксина Table пропадет isLoading нужно переименовать.
      isLoadingEfficiency () {
        return this.$wait(`admin/efficiency/${ GET_EFFICIENCY }`);
      }
    },
    methods: {
      ...mapActions("admin/efficiency", [
        GET_EFFICIENCY
      ]),
  
      coloredStatus (status) {
        return status ? "is-success" : "is-danger";
      }
    }
  };
</script>

<style scoped lang="scss">

  ::v-deep {
      .progressbar {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $primary-light;
      }
  }

</style>
